import styles from "components/layout/palette/HorizontalPosts.module.scss";
import SimplePostListCard from "components/cards/SimplePostListCard";
import ShowMoreIcon from "images/icons/right_arrow_with_circle.svg";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";
import classNames from "classnames";


const HorizontalPosts = (props) => {
    const {
        posts, onPostClick, imageRatio, width, showUser, hideScrapButton,
        onShowMoreClick, hideTextAndProduct, mediaIconPos,
        renderItem, cardWrapperClassName, scrollRef, showText, largeIcon, showInlineProduct
    } = props;

    const showMoreHeight = imageRatio ? width * parseFloat(imageRatio.replace('%', '')) / 100 : width;

    return (
        <OverlayScrollbarsComponent
            defer
            className={styles.postsWrapper}
            options={{
                scrollbars: {
                    autoHide: 'leave',
                    autoHideDelay: 100,
                    theme: 'os-theme-light',
                },
            }}
        >
            <div className={styles.postsWrapper} {...{'body-scroll-lock-ignore': "true"}} ref={scrollRef}>
                <div className={styles.horizontalScrollPaddingLeft} />
                {
                    posts.map((post, index) => {
                        return (
                            <div className={classNames(styles.cardWrapper, cardWrapperClassName)} key={index} style={{flexBasis: width}}>
                                {
                                    renderItem
                                        ? renderItem(post, index)
                                        :
                                        <SimplePostListCard
                                            post={post}
                                            hideText={!showText}
                                            miniIcon={!largeIcon}
                                            onPostClick={() => onPostClick(post, index)}
                                            imageRatio={imageRatio}
                                            showUser={showUser}
                                            showInlineProduct={showInlineProduct}
                                            hideScrapButton={hideScrapButton}
                                            hideTextAndProduct={hideTextAndProduct}
                                            mediaIconPos={mediaIconPos}
                                        />
                                }
                            </div>
                        )
                    })
                }
                {
                    onShowMoreClick &&
                    <div className={styles.showMoreContainer} onClick={onShowMoreClick} style={{height: showMoreHeight}}>
                        <ShowMoreIcon/>
                        <span className={styles.showMoreText}>더보기</span>
                    </div>
                }
                <div className={styles.horizontalScrollPaddingRight} />
            </div>
        </OverlayScrollbarsComponent>
    )
};

export default HorizontalPosts;
