import classNames from "classnames";
import styles from "components/cards/search/BrandCircleCard.module.scss";
import Image from "next/image";
import {getBrandRoute} from "common/const";
import Link from "next/link";


const BrandCircleCard = (props) => {
    const {brand, isColorBackground} = props;

    const onClick = props.onClick || (() => {
    });

    const brand_logo_url = (isColorBackground ? brand.logo_image_with_background_url : brand.logo_image_url) ?? brand.logo_image_url;

    return (
        <Link href={getBrandRoute(brand.id)}>
            <a className={styles.root} onClick={onClick}>
                <div className={classNames(styles.brandImage)}>
                    {
                        brand_logo_url
                            ? <Image unoptimized src={brand_logo_url} layout="fill" objectFit="cover"/>
                            : <div className={styles.defaultCircle}/>
                    }
                </div>
                <div className={styles.brandName}>
                    <span className={styles.brandNameText}>{brand.name}</span>
                </div>
            </a>
        </Link>
    )
};

export default BrandCircleCard;
