import styles from "components/layout/store/brands/HorizontalBrands.module.scss";
import ShowMoreIcon from "images/icons/right_arrow_with_circle.svg";
import BrandCircleCard from "components/cards/search/BrandCircleCard";
import {OverlayScrollbarsComponent} from "overlayscrollbars-react";


const HorizontalBrands = (props) => {
    const {
        brands, onBrandClick, width, onShowMoreClick, isColorBackground
    } = props;

    return (
        <OverlayScrollbarsComponent
            defer
            className={styles.listContainer}
            // options={{
            //     scrollbars: {
            //         autoHide: 'leave',
            //         autoHideDelay: 100,
            //         theme: 'os-theme-light',
            //     },
            // }}
        >
            <div className={styles.listContainer} {...{'body-scroll-lock-ignore': "true"}}>
                {
                    brands.map((e, index) => {
                        return (
                            <div className={styles.cardWrapper} key={index} style={{flexBasis: width}}>
                                <BrandCircleCard
                                    brand={e}
                                    onClick={() => onBrandClick(e, index)}
                                    isColorBackground={isColorBackground}
                                />
                            </div>
                        )
                    })
                }
                {
                    onShowMoreClick &&
                    <div className={styles.showMoreContainer} onClick={onShowMoreClick}>
                        <ShowMoreIcon/>
                        <span className={styles.showMoreText}>더보기</span>
                    </div>
                }
            </div>
        </OverlayScrollbarsComponent>
    )
};

export default HorizontalBrands;
